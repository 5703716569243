import { useResizeObserver } from '@vueuse/core'
import type { MaybeRef } from '@vueuse/shared'
import { toValue } from '@vueuse/shared'
import type { WatchSource } from 'vue'

export interface UseTextareaAutosizeOptions {
    /** Textarea element to autosize. */
    element?: MaybeRef<HTMLTextAreaElement | undefined>
    /** Textarea content. */
    input?: MaybeRef<string | undefined>
    /** Watch sources that should trigger a textarea resize. */
    watch?: WatchSource | Array<WatchSource>
    /** Function called when the textarea size changes. */
    onResize?: () => void
    /** Specify style target to apply the height based on textarea content. If not provided it will use textarea it self.  */
    styleTarget?: MaybeRef<HTMLElement>
    /** Specify the style property that will be used to manipulate height. Can be `height | minHeight`. Default value is `height`. */
    styleProp?: 'height' | 'minHeight'
    maxHeight?: MaybeRef<string>
}

export function useTextareaAutosize(options?: UseTextareaAutosizeOptions) {
    const textarea = ref<HTMLTextAreaElement>(options?.element as any)
    const input = ref<string>(options?.input as any)
    const styleProp = options?.styleProp ?? 'height'
    const textareaScrollHeight = ref(1)

    function triggerResize() {
        if (!textarea.value) return

        let height = ''

        textarea.value.style[styleProp] = '1px'

        const maxHeight = options?.maxHeight
            ? parseInt(toValue(options.maxHeight))
            : Infinity

        textareaScrollHeight.value =
            textarea.value?.scrollHeight < maxHeight
                ? textarea.value?.scrollHeight
                : maxHeight

        // If style target is provided update its height
        if (options?.styleTarget)
            toValue(options.styleTarget).style[styleProp] =
                `${textareaScrollHeight.value}px`
        // else update textarea's height by updating height variable
        else height = `${textareaScrollHeight.value}px`

        textarea.value.style[styleProp] = height

        options?.onResize?.()
    }

    watch([input, textarea], () => nextTick(triggerResize), { immediate: true })

    useResizeObserver(textarea, () => triggerResize())

    if (options?.watch)
        watch(options.watch, triggerResize, { immediate: true, deep: true })

    return {
        textarea,
        input,
        triggerResize,
    }
}

export type UseTextareaAutosizeReturn = ReturnType<typeof useTextareaAutosize>
