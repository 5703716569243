import useVuelidate, {
    BaseValidation,
    ValidationArgs,
    GlobalConfig as VuelidateConfig,
    Validation,
} from '@vuelidate/core'

type ToRefs<T> = { [K in keyof T]: Ref<T[K]> }

interface ValidationConfig<Vargs> extends VuelidateConfig {
    externalResultsMap: Record<string, keyof Vargs>
}
export default function useValidation<
    T extends { [key in keyof Vargs]: any },
    Vargs extends ValidationArgs = ValidationArgs,
>(
    validationsArgs: Ref<Vargs> | Vargs,
    state: T | Ref<T> | ToRefs<T>,
    config?: ValidationConfig<Vargs>
): {
    v: Ref<Validation<Vargs, T>>
    setExternalResults: typeof setExternalResults
    handleSubmit: typeof handleSubmit
} {
    const externalResults = ref<Record<string, string>>({})

    function setExternalResults<T extends Record<string, string>>(results: T) {
        externalResults.value = mapEntries(results, (key, value) => {
            const _key =
                config && key in config.externalResultsMap
                    ? config.externalResultsMap[key]
                    : key

            return [_key, value]
        })
    }

    const vuelidateConfig = computed(() => {
        return {
            $lazy: true,
            $autoDirty: true,
            $externalResults: externalResults,
            $rewardEarly: true,
            ...(config ?? {}),
        }
    })

    const v = useVuelidate(validationsArgs, state, vuelidateConfig.value)

    async function handleSubmit(callback: () => any) {
        if (!(await v.value.$validate())) {
            return
        }

        return callback()
    }

    return {
        v,
        setExternalResults,
        handleSubmit,
    }
}

export type { BaseValidation }
