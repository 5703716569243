import {
    required,
    email,
    minLength,
    sameAs,
    helpers,
} from '@vuelidate/validators'

const { withMessage } = helpers

export { required, email, sameAs, minLength, withMessage }
